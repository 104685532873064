export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

export const pageview = () => {
    if (!GA_TRACKING_ID) return;
    // See _document.tsx
    (window as any).gaPageview?.();
};

export const event = (action: string, data: Record<string, any> = {}) => {
    if (!GA_TRACKING_ID) return;
    (window as any).gtag("event", action, {
        ...data,
    });
};
