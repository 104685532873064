import { AnyObject } from "utils";
import { fire } from "utils/events";

export type Level = "log" | "debug" | "info" | "warn" | "warning" | "error" | "critical";

const doLog = (level: Level) => (msg: string, obj?: AnyObject, err?: Error) => {
    const args: any[] = [msg];
    if (obj) args.push(obj);
    if (err) args.push(err);

    const rollbar = (window as any)?.Rollbar;
    if (rollbar) rollbar[level](...args);
    // eslint-disable-next-line no-console
    console.log(`[${level}]:`, ...args);
    fire(`log_${level}`, { message: msg });
};

const logger = {
    log: doLog("log"),
    debug: doLog("debug"),
    info: doLog("info"),
    warn: doLog("warn"),
    warning: doLog("warning"),
    error: doLog("error"),
    critical: doLog("critical"),
};
export default logger;

