import fetch from "isomorphic-unfetch";
import cookies from "js-cookie";

import logger from "utils/logger";

const portalId = "21202254";

export function hubspotEnabled() {
    return process.env.NEXT_PUBLIC_ADAPTABLE_ENV === "prod";
}

export function trackPageView(path: string) {
    if (!hubspotEnabled()) return;
    // eslint-disable-next-line no-multi-assign
    const hsq = (window as any)._hsq = (window as any)._hsq || [];
    hsq.push(["setPath", path]);
    hsq.push(["trackPageView"]);
}

export interface FormField {
    name: string;
    value: string;
}

export interface FormSubmit {
    fields: FormField[];
    formGuid: string;
    pageName: string;
    pathname: string;
}

export async function formSubmit({
    fields,
    formGuid,
    pageName,
    pathname,
}: FormSubmit) {
    if (!hubspotEnabled()) return;

    const request = {
        fields,
        context: {
            hutk: cookies.get("hubspotutk"),
            pageUri: `adaptable.io${pathname}`,
            pageName,
        },
    };

    let resp;
    try {
        resp = await fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        });
    } catch (err) {
        logger.critical(`Network error sending ${pageName} form`, request, err);
        throw new Error(`There was a network error submitting your request: ${err.message}`);
    }
    if (!resp.ok) {
        const body = await resp.json();
        logger.critical(`Error sending ${pageName} form`, {
            responseCode: resp.status,
            body,
            request,
        });
        throw new Error(`There was an unexpected error submitting your request`);
    }
}
