/**
 * Retrieve the value of a query string variable, ignoring all but the
 * first value if multiples exist.
 */
// eslint-disable-next-line import/prefer-default-export
export function querySingleItem(item: string | string[] | undefined): string | undefined {
    return Array.isArray(item) ? item[0] : item;
}

export const appUrls = {
    activityDetail: "/app/activitydetail",
    admin: "/app/admin",
    appStatus: "/app/appstatus",
    billing: "/app/billing",
    connectRepo: "/app/connectrepo",
    dashboard: "/app/dashboard",
    deployDemo: "/app/choosestarter",
    deployStarter: "/app/choosestarter",
    fromStarter: "/app/fromstarter",
    getStarted: "/app/connectrepo",
    newDeploy: "/app/deploy/new",
    organizations: "/app/organizations",
    signin: "/app/signin",
};

export const siteUrls = {
    domainsDoc: "/docs/adding-a-custom-domain",
    feathersGuide: "/docs/app-guides/deploy-feathers-js-api",
    privacy: "/privacy",
    requestTrial: "/contact?category=TRIAL_REQUEST&summary=Additional+trial+credit+to+try+the+Build+Plan&details=%23%23+Please+add+information+on+why+you%27re+requesting+additional+trial+credits",
    support: "/contact",
    terms: "/terms",
    whatIsAdaptable: "/docs/what-is-adaptable",
};

export type AnyObject = Record<string, any>;
export type EmptyObject = Record<string, never>;

export const toPlainObject = (o: any) => JSON.parse(JSON.stringify(o));

export * from "./url";

export const plural = (n: number) => (n === 1 ? "" : "s");
