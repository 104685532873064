import { useRouter } from "next/router";

import logger from "utils/logger";

class URLLike {
    hash: string;
    pathname: string;
    search: string;

    constructor(path: string) {
        const dummyPrefix = "https://stripme";
        const {
            hash,
            pathname,
            search,
        } = new URL(path, dummyPrefix);
        this.hash = hash;
        this.pathname = pathname;
        this.search = search;
    }

    toString() {
        return `${this.pathname}${this.search}${this.hash}`;
    }
}

export const toURLLike = (url: string) => {
    try {
        return new URL(url);
    } catch {
        return new URLLike(url);
    }
};

/**
 * Removes a key/value pair out of the hash portion of a uri.
 *
 * Treats the hash portion of a uri as either a single anchor tag or as a
 * (non-standard usage) set of key/value pairs encoded like URL search
 * parameters. If the specified keys exist in the hash, they are removed.
 * If the single anchor tag matches one of the keys, the anchor is removed.
 * Any remaining key value pairs are left intact.
 */
export const hashRemove = (uri: string, keys: string[]) => {
    const hPos = uri.indexOf("#");
    if (hPos === -1) return uri;
    const baseUri = uri.slice(0, hPos);
    // Remove any repeated hash marks
    const origHash = uri.slice(hPos + 1).replace(/^#+/, "");
    // If there's nothing after the hash, just return the base
    if (!origHash) return baseUri;

    const origParamList = origHash.split("&");
    const paramList = origParamList.filter((p) => {
        for (const k of keys) {
            if (p === k || p.startsWith(`${k}=`)) return false;
        }
        return true;
    });
    if (paramList.length === 0) return baseUri;

    return `${baseUri}#${paramList.join("&")}`;
};

export const getHashError = (uri: string) => {
    const parsed = toURLLike(uri);
    if (parsed.hash.startsWith("#error=")) {
        const rawErr = parsed.hash.replace(/#error=/, "");
        return decodeURIComponent(rawErr);
    }
    return undefined;
};

/**
 * Given either a URL path (starting with "/") or a full URL, returns only the
 * path and hash portions, **unmodified**.
 */
export const getPathAndHash = (uri: string) => {
    if (uri.startsWith("/")) return uri;
    try {
        // eslint-disable-next-line no-new
        new URL(uri); // Just throw on invalid URL
    } catch (err) {
        throw new Error(`Invalid URI (${uri}). Must start with "/" or be a fully formed URL.`);
    }

    // Drop everything up to and including the first 3 slashes
    return `/${uri.split("/").slice(3).join("/")}`;
};

/**
 * If href is a relative URL href, return an absolute path, resolved against
 * asPath. If href was already fully resolved, just return that.
 */
export const resolveHref = ({ asPath, href }: { asPath: string; href: string; }) => {
    try {
        // eslint-disable-next-line no-new
        new URL(href);
        // href has a protocol and is already fully resolved
        return href;
    } catch (_) {
        // Fall through
    }

    const dummyPrefix = "https://stripme";
    const baseUrl = new URL(asPath, dummyPrefix);
    const url = new URL(href, baseUrl);
    return url.href.substring(dummyPrefix.length);
};

export function getHash() {
    let hash = typeof window === "object" ? window.location.hash : "";
    hash = hash.slice(1); // Remove hash sign
    return hash;
}

export function useCanonical() {
    const { asPath } = useRouter();
    const [path] = asPath.split("#");
    return `https://adaptable.io${path}`;
}

/**
 * This function is specifically designed for use as a catch handler for the
 * Next router's `push` and `replace` methods. It logs any errors generated
 * by those methods, but otherwise ignores the errors.
 *
 * When the `ignoreCancel` option is true, it silently ignores the
 * `Cancel rendering route` error that happens if these router methods are
 * called in quick succession. This error particularly seems to happen with
 * hash changes.
 *
 * The intended use is:
 *   router.push(...).catch(routerCatch());
 */
export interface RouterCatchOpts {
    ignoreCancel?: boolean;
    msg?: string;
}

export function routerCatch({
    ignoreCancel = false,
    msg = "Error during router navigation",
}: RouterCatchOpts = {}) {
    return (err: any) => {
        if (ignoreCancel && err?.cancelled) return;
        logger.error(msg, {}, err);
    };
}
