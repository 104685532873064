import {
    createTheme, responsiveFontSizes, Theme as MuiTheme, ThemeOptions,
} from "@mui/material/styles";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends MuiTheme {}
}

declare module "@emotion/react" {
    export interface Theme extends MuiTheme {}
}

const baseThemeConfig: ThemeOptions = {
    components: {
        MuiLink: {
            defaultProps: {
                underline: "hover",
            },
        },
    },
    palette: {
        primary: {
            main: "#363a94",
            light: "#6964c5",
            dark: "#001465",
        },
        secondary: {
            main: "#dd1e50",
            light: "#ff5e7c",
            dark: "#a40028",
        },
        background: {
            default: "#f8f7fc",
            paper: "#ffffff",
        },
    },
    typography: {
        fontFamily: "Poppins,sans-serif",
        h1: {
            fontSize: "3.2rem",
            fontWeight: 600,
        },
        h2: {
            fontSize: "2.5rem",
            fontWeight: 500,
        },
        h3: {
            fontSize: "2rem",
        },
        h4: {
            fontSize: "1.7rem",
        },
        h5: {
            fontSize: "1.5rem",
        },
    },
    mixins: {
        toolbar: {
            minHeight: "74px",
        },
    },
};

// Create the theme instance for the app and content pages (except docs)
const theme = responsiveFontSizes(createTheme(baseThemeConfig));
export default theme;

// The options are slightly different for docs
export const docsTheme = responsiveFontSizes(createTheme(baseThemeConfig, {
    components: {
        MuiTypography: {
            styleOverrides: {
                h1: {
                    marginBottom: "1.2em",
                },
                h2: {
                    marginTop: "1.5em",
                    marginBottom: ".7em",
                },
                h3: {
                    marginTop: "1.2em",
                    marginBottom: ".6em",
                },
            },
        },
    },
    typography: {
        h1: {
            fontSize: "2.5rem",
            fontWeight: 600,
        },
        h2: {
            fontSize: "1.7rem",
            fontWeight: 500,
        },
        h3: {
            fontSize: "1.5rem",
        },
        h4: {
            fontSize: "1.3rem",
        },
        h5: {
            fontSize: "1.2rem",
        },
    },
}));
