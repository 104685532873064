import makeStyles from "@mui/styles/makeStyles";
import equal from "fast-deep-equal";
import {
    PostHogProvider as PostHogProviderOrig, usePostHog,
} from "posthog-js/react";
import React, { ReactNode, useEffect, useState } from "react";

import * as posthog from "utils/posthog";

type Flags = Record<string, string | boolean>;

const useStyles = makeStyles({
    fflags: (flags: Flags) => {
        const styles: any = {
            // All outer wrappers need height: 100% for proper positioning
            // of the footer
            height: "100%",

            // If we don't get any flags or we don't get a specific flag,
            // only display the default.
            "& .fflag": {
                display: "none",
            },
            "& .fflag-default": {
                display: "block",
            },
        };
        for (const [flag, val] of Object.entries(flags)) {
            // If the flag value is false, we should still display the default
            // so no need to add more styles.
            if (val) {
                // First change all elements associated with this flag to
                // display: none, overriding above.
                styles[`& .fflag-default.fflag-${flag}`] = {
                    display: "none",
                };
                // Then for the active variant, override the previous styles
                // to display only the specific variant.
                styles[`& .fflag.fflag-${flag}-${val}`] = {
                    display: "block",
                };
                styles[`& .fflag-default.fflag-${flag}-${val}`] = {
                    display: "block",
                };
            }
        }

        return styles;
    },
});

interface PostHogProviderProps {
    children: ReactNode;
}

/**
 * Provides page-wide styles that allow displaying content based on feature flags.
 * Note that this is NOT the preferred way to handle feature flags. Use either
 * components/PostHogCond or the posthog-js/react package instead.
 *
 * Example usage for a variant flag "my-flag" with values "v1" and "v2":
 *
 * <div class="fflag-default fflag-my-flag fflag-my-flag-v1">
 *   Displays by default and for v1
 * </div>
 * <div class="fflag fflag-my-flag fflag-my-flag-v2">
 *   Displays only for v2
 * </div>
 */
function PostHogStyles({ children }: PostHogProviderProps) {
    const client = usePostHog();
    const [flags, setFlagsInt] = useState<Record<string, string | boolean>>({});
    const { fflags } = useStyles(flags);

    const setFlags = (newFlags: Record<string, string | boolean>) => {
        if (!equal(flags, newFlags)) {
            setFlagsInt(newFlags);
        }
    };

    useEffect(() => client.onFeatureFlags(() => {
        const newFlags = client.featureFlags.getFlagVariants();
        setFlags(newFlags);
    }));

    return <div className={fflags}>{children}</div>;
}


export default function PostHogProvider({ children }: PostHogProviderProps) {
    return (
        <PostHogProviderOrig client={posthog.client}>
            <PostHogStyles>
                {children}
            </PostHogStyles>
        </PostHogProviderOrig>
    );
}
