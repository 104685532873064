// Change to enable in dev, etc
const alwaysEnabled = true;

export function intercomEnabled() {
    return (
        alwaysEnabled
        || ["prod", "stage"].includes(process.env.NEXT_PUBLIC_ADAPTABLE_ENV || "")
    );
}

export const intercomParams = {
    api_base: "https://api-iam.intercom.io",
    app_id: "pbiq0o0p",
};

/**
 * We purposely track a different set of events for Intercom. Intercom is not
 * an analytics platform, so it's not useful to spam it with tons of events.
 * We want customer-meaningful events that we might act on using Intercom.
 *
 * Intercom event naming convention is kebab case (dashes).
 */
export type IntercomEvent = (
    | "plan-canceled" // Successfully canceled plan
    | "app-deleted" // Successfully deleted app
);

export function intercomEvent(name: IntercomEvent, data?: Record<string, any>) {
    if (typeof window === "undefined") return;
    (window as any).Intercom?.("trackEvent", name, data);
}

export function openIntercomChat() {
    if (typeof window === "undefined") return;
    if ("Intercom" in window) {
        (window as any).Intercom?.("show");
    } else {
        throw new Error("Unable to open the chat window. Please ensure that your browser and privacy software do not block intercom.io");
    }
}
