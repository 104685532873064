import { EventTarget } from "event-target-shim";
import { useEffect, useState } from "react";

const onlineStates = ["online", "offline"] as const;
export type OnlineState = typeof onlineStates[number];

// Wait this long before deciding the network is down (and showing the user)
const networkFailureDelay = 10 * 1000;


class Online extends EventTarget {
    state_: OnlineState = "online";
    failureTimer_: NodeJS.Timeout | undefined;

    get state() {
        return this.state_;
    }

    set state(state: OnlineState) {
        if (!onlineStates.includes(state)) throw new Error(`Invalid OnlineState ${state}`);
        if (state !== this.state_) {
            this.state_ = state;
            this.dispatchEvent(new CustomEvent("stateUpdate", { detail: { state } }));
        }
    }

    get isOnline() {
        return this.state_ === "online";
    }

    networkFailure() {
        if (this.state === "offline" || this.failureTimer_) return;

        this.failureTimer_ = setTimeout(() => {
            this.failureTimer_ = undefined;
            this.state = "offline";
        }, networkFailureDelay);
    }

    networkSuccess() {
        if (this.failureTimer_) {
            clearTimeout(this.failureTimer_);
            this.failureTimer_ = undefined;
        }
        this.state = "online";
    }
}

const online = new Online();
export default online;

export function useIsOnline() {
    const [isOnline, setIsOnline] = useState(online.isOnline);
    useEffect(() => {
        const updateState = () => setIsOnline(online.isOnline);

        online.addEventListener("stateUpdate", updateState);

        return () => {
            online.removeEventListener("stateUpdate", updateState);
        };
    });

    return isOnline;
}
