import auth, { AuthenticationClient } from "@feathersjs/authentication-client";
import feathers from "@feathersjs/feathers";
import rest from "@feathersjs/rest-client";
import qs from "qs";

import { apiUrl } from "utils/config";
import { login } from "utils/events";
import fetch from "utils/fetch";
import logger from "utils/logger";

let client: feathers.Application;

class AdaptableAuth extends AuthenticationClient {
    // Try to get a once-per-session login event by noticing when the
    // Feathers client saves a *new* access token.
    // The feathers client re-authenticates and re-saves the access token
    // frequently, at least on every page load, if not more.
    async setAccessToken(accessToken: string) {
        const existing = await this.getAccessToken();
        if (existing !== accessToken) {
            // This is a little weird because setAccessToken is called in
            // the path to resolving the authentication promise. So we
            // need to let the authentication promise resolve first.
            const pAuth = this.app.get("authentication");
            if (pAuth) {
                pAuth
                    .then(({ authentication, user }: any) => {
                        const method = authentication?.payload?.githubToken ? "github" : "unknown";

                        login(user._id, method, user);
                    })
                    .catch((err: any) => {
                        logger.warn(`Unable to send login event: ${err.message}`, err);
                    });
            } else {
                logger.warn(`Unable to send login event: no authentication promise`);
            }
        }
        await super.setAccessToken(accessToken);
    }
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { FetchClient } = require("@feathersjs/rest-client");

class StrictNullFetchClient extends FetchClient {
    // eslint-disable-next-line class-methods-use-this
    getQuery = (query: any) => {
        if (Object.keys(query).length !== 0) {
            const queryString = qs.stringify(query, {
                strictNullHandling: true,
            });
            return `?${queryString}`;
        }
        return "";
    };
}

// FIXME(manishv) We should at some point change this
export const storageKey = "feathers-jwt";
export default function feathersClient() {
    if (!client) {
        if (!apiUrl) throw new Error(`apiUrl is not defined`);

        const url = apiUrl.replace(/\/+$/, "");

        const restClient = rest(url);
        const app = feathers();
        client = app
            .configure(restClient.fetch(fetch, StrictNullFetchClient))
            .configure(auth({
                Authentication: AdaptableAuth,
                storageKey,
            }));
    }
    return client;
}

export { GeneralError, NotFound } from "@feathersjs/errors";
