import cookies from "js-cookie";
import { createContext, useContext } from "react";

export interface FeatureFlags {
    domains?: boolean;
    organizations?: boolean;
}

/**
 * The default for everyone. Can be overridden by setting the cookie.
 */
const defaultFlags: FeatureFlags = {
    domains: true,
    organizations: true,
};

const cookieName = "adaptable-features";
let cookieFlags: FeatureFlags = {};

try {
    const c = cookies.get(cookieName);
    if (c) {
        const val = JSON.parse(c);
        if (typeof val !== "object") throw new Error(`not an object`);
        cookieFlags = val;
    }
} catch (err) {
    // eslint-disable-next-line no-console
    console.warn(`Invalid adaptable-features cookie: ${err.message}`);
}

export const FeatureFlagContext = createContext<FeatureFlags>({
    ...defaultFlags,
    ...cookieFlags,
});

export function useFeatureEnabled(flag: keyof FeatureFlags) {
    const ctx = useContext(FeatureFlagContext);
    return ctx[flag] === true;
}

export function installAdaptableFeature() {
    if (typeof window === "undefined") return;

    /**
     * Convenience function to force a feature flag to enabled or disabled
     * by setting the cookie.
     */
    // eslint-disable-next-line no-inner-declarations
    function adaptableFeature(flag: keyof FeatureFlags, enabled: boolean | "default") {
        if (enabled === "default") {
            delete cookieFlags[flag];
        } else if (typeof enabled === "boolean") {
            cookieFlags[flag] = enabled;
        } else {
            throw new Error(`Invalid value for enabled`);
        }
        cookies.set(cookieName, JSON.stringify(cookieFlags), {
            expires: 30,
        });
        return cookieFlags;
    }
    (window as any).adaptableFeature = adaptableFeature;
}
