import { AnyObject } from "@adaptable/utils";
import { useRouter } from "next/router";
import Script from "next/script";
import React, { useEffect, useRef } from "react";

import { intercomEnabled, intercomParams } from "utils/intercom";
import { useAuth } from "utils/auth";


type IntercomState = "initial" | "visitor" | "user";

export default function Intercom() {
    const auth = useAuth();
    const intercomState = useRef<IntercomState>("initial");
    const intercomUserData = useRef<AnyObject>({});
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = () => {
            (window as any).Intercom?.("update", {
                ...intercomParams,
                ...intercomUserData.current,
            });
        };
        router.events.on("routeChangeComplete", handleRouteChange);
        router.events.on("hashChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
            router.events.off("hashChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        const boot = () => {
            // Only send email if we have the associated authentication hash.
            // Without the hash, we can't verify they're really a user.
            if (auth.user && auth.intercomHash) {
                intercomUserData.current = {
                    user_hash: auth.intercomHash,
                    email: auth.user.email,
                    created_at: auth.user.createdAt,
                    avatar: {
                        type: "avatar",
                        image_url: auth.user.avatar,
                    },
                };

                intercomState.current = "user";
            } else {
                intercomState.current = "visitor";
            }

            (window as any).Intercom?.("boot", {
                ...intercomParams,
                ...intercomUserData.current,
            });
        };

        const authUpdate = () => {
            // Once auth.state becomes complete, boot if we haven't already
            if (auth.state === "complete" && intercomState.current === "initial") {
                boot();
            }
        };

        const userUpdate = () => {
            if (auth.user) {
                // Boot a second time if we weren't already in user mode
                if (auth.state === "complete" && intercomState.current !== "user") {
                    boot();
                }
                return;
            }

            // No user
            if (intercomState.current === "user") {
                // Log out from Intercom and boot again as a visitor
                intercomUserData.current = {};
                (window as any).Intercom?.("shutdown");
                boot();
            }
        };

        authUpdate();

        auth.addEventListener("state", authUpdate);
        auth.addEventListener("user", userUpdate);

        return () => {
            auth.removeEventListener("state", authUpdate);
            auth.removeEventListener("user", userUpdate);
        };
    }, [auth]);

    if (!intercomEnabled()) return null;

    return (
        <Script id="intercom">
            {`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/pbiq0o0p';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
        </Script>
    );
}
