import fetchRetry from "fetch-retry";
import origFetch from "isomorphic-unfetch";

import online from "utils/online";
import { appUrls } from "utils";
import { jwtInvalidOrExpired, logoutAndRedirectToSignIn } from "./auth";
import { apiUrl } from "./config";

const retries = [1000, 2000, 4000, 8000, 10000];

const fetch = fetchRetry(origFetch, {
    retryDelay: (attemptNum, _err, _resp) => retries[Math.min(attemptNum, retries.length - 1)],

    // Retries indefinitely on failure. Note that this function also gets called
    // after a successful response.
    retryOn: (_attemptNum, err, resp) => {
        const isNetworkFail = Boolean(err || (resp && [502, 503, 504].includes(resp.status)));
        if (isNetworkFail) {
            online.networkFailure();
        } else if (resp) {
            online.networkSuccess();
            if (apiUrl
                && resp.url.startsWith(apiUrl)
                && !resp.url.startsWith(`${apiUrl}${appUrls.signin}`)
                && !resp.url.startsWith(`${apiUrl}/authentication`)
                && [401, 403, 404].includes(resp.status)) {
                if (jwtInvalidOrExpired()) {
                    logoutAndRedirectToSignIn();
                    return false;
                }
            }
        }
        return isNetworkFail;
    },
});

export default fetch;
