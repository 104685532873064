function protocolAndHost() {
    switch (process.env.NEXT_PUBLIC_ADAPTABLE_ENV) {
        case "stage": return "https://adaptable.adaptlify.com";
        default: return "https://adaptable.io";
    }
}

// eslint-disable-next-line import/prefer-default-export
export const ogImages = [
    {
        url: `${protocolAndHost()}/img/facebook%20card%20tagline.png`,
        width: 1200,
        height: 628,
        alt: "Adaptable.io",
        type: "image/png",
    },
    {
        url: `${protocolAndHost()}/img/twitter%20card%20tagline.png`,
        width: 800,
        height: 418,
        alt: "Adaptable.io",
        type: "image/png",
    },
];
