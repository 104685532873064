import { User } from "@adaptable/client/dist/api-types/users";
import * as gtag from "utils/gtag";
import * as hj from "utils/hotjar";
import type { Level } from "utils/logger";
import * as posthog from "utils/posthog";

export type Event = (
    //
    // Sign in page
    //
    | "gitlab_beta_dialog_cancel"
    | "gitlab_beta_waitlist_signup"
    | "gitlab_signinorup_clicked"
    | "sign_up" // Clicked Sign Up button

    //
    // Connect repo screen
    //
    | "connect_repo_private" // Clicked on a private repo
    | "connect_repo_public" // Clicked on a public repo
    | "connect_repo_subs_cancel" // Canceled from private repo with no trial dialog
    | "connect_repo_subs_enroll" // Continued from private repo with no trial dialog
    | "connect_repo_trial_cancel" // Canceled from private repo with trial dialog
    | "connect_repo_trial_enroll" // Continued from private repo with trial dialog

    //
    // New deploy workflow
    //
    // These events tell which version of the payment page the user is shown.
    // They should be mutually exclusive for a given workflow.
    | "deploy_nopaymentstep" // Payment step not shown
    | "deploy_existing_unattached" // User already has an unattached subs
    | "deploy_paid"
    | "deploy_trial"
    | "deploy_verify"

    | "deploy_checkout_verify" // Starting checkout for verifying payment method
    | "deploy_checkout_paid" // Starting checkout for paid subscription
    | "deploy_checkout_trial" // Starting checkout for trial
    | "deploy_checkout_existing" // Showing checkout for user with existing subscriptions

    // This event is the closest we have to saying they successfully made it
    // past the payment/verification step, but it actually indicates that
    // they got to the step beyond payment.
    | "deploy_payment_done"

    | "initial_deploy_failed" // Deploy failed for any reason
    | "initial_deploy_success" // Deploy succeeded

    //
    // Starter workflow
    //
    | "starter_noverifystep" // Verify step not shown
    | "starter_verify" // Verify step shown
    // This event is the closest we have to saying they successfully made it
    // past the verification step.
    | "starter_verify_done"

    //
    // Change Plan dialog
    //
    | "plan_canceled" // Successfully canceled plan
    | "change_plan_dialog" // User opened the dialog
    | `plan_interest_${string}` // User clicked "Select Plan" in the dialog

    //
    // Pricing page
    //
    | `pricing_start_${string}` // User clicked Get Started Now for a plan

    //
    // App events
    //
    | "app_deleted" // Successfully deleted app

    | `log_${Level}` // Log message was generated
    | "checkout_paid"
    | "checkout_trial"
);

export const fire = (action: Event, data: Record<string, any> = {}) => {
    gtag.event(action, data);
    hj.event(action);
    posthog.event(action, data);
};

export const login = (userId: string, method: string, user: User) => {
    gtag.event("login", { method });
    hj.event("login");
    posthog.login(userId, method, user);

    if (typeof window !== "undefined") {
        (window as any).Rollbar?.configure({
            payload: {
                person: {
                    id: userId,
                },
            },
        });
    }
};

// Create an event saying what feature flags are active. This is redundant
// for PostHog, but useful for others.
posthog.client.onFeatureFlags((_flags, variants) => {
    for (const [flag, val] of Object.entries(variants)) {
        fire(`${flag}_${val}` as any);
    }
});
