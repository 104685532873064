import { User } from "@adaptable/client/dist/api-types/users";
import { EventTarget } from "event-target-shim";
import cookies from "js-cookie";
import { createContext, useContext } from "react";

import feathersClient, { storageKey } from "api-support/feathers-client";
import { appUrls } from "utils";
import { apiUrl } from "utils/config";
import logger from "utils/logger";
import { getPathAndHash, hashRemove } from "utils/url";
import { setUserId } from "utils/userId";

export * from "@adaptable/client/dist/api-types/users";

/**
 * Only exported for test.
 */
export const hasSignedInCookie = "adaptable-has-signed-in";

/**
 * Returns true if a user has ever signed in on this browser, as determined
 * by presence of a cookie.
 */
export const hasSignedIn = () => Boolean(cookies.get(hasSignedInCookie));
const setHasSignedIn = () => cookies.set(hasSignedInCookie, "true", { expires: 365 });

// TODO: Share this with Feathers
export interface Permissions {
    roles: UserRole[];
}

// TODO: Share this with Feathers
export interface UserRole {
    role: string;
}

export function userIsAdmin(user: User | undefined) {
    const roles = user?.permissions?.roles as any;
    if (!roles || !Array.isArray(roles)) return false;
    return roles.some((r) => r.role === "admin");
}

export type AuthState = "unknown" | "complete";

export interface AuthInfo extends EventTarget {
    githubToken?: string;
    intercomHash?: string;
    user?: User;
    error?: Error | undefined;
    logout: () => void;
    isAdmin: boolean;
    state: AuthState;
}

export class AuthInfoImpl extends EventTarget implements AuthInfo {
    githubToken_: string | undefined;
    intercomHash: string | undefined;
    user_: User | undefined = undefined;
    error: Error | undefined = undefined;
    state_: AuthState = "unknown";

    set githubToken(githubToken: string | undefined) {
        this.githubToken_ = githubToken;
        this.dispatchEvent(new CustomEvent("githubToken", { detail: { githubToken } }));
    }

    get githubToken() { return this.githubToken_; }

    set user(u: User | undefined) {
        this.user_ = u;
        if (u) {
            setHasSignedIn();
            setUserId(u._id);
        }
        this.dispatchEvent(new CustomEvent("user", { detail: { user: u } }));
    }

    get user() { return this.user_; }

    set state(state: AuthState) {
        if (this.state_ === state) return;
        this.state_ = state;
        this.dispatchEvent(new CustomEvent("state", { detail: { state } }));
    }

    get state() { return this.state_; }

    get isAdmin() { return userIsAdmin(this.user_); }

    logout() {
        feathersClient()
            .logout()
            .then(() => this.reset())
            .catch((err) => {
                logger.warning(`Unable to logout: ${err}`, {
                    user: this.user_,
                    state: this.state,
                }, err);
                this.reset();
            });
    }

    reset() {
        this.intercomHash = undefined;
        this.user = undefined;
        this.githubToken = undefined;
        this.error = undefined;
        this.state = "unknown";
    }
}

// Exported for testing only
export const defaultAuthInfo = new AuthInfoImpl();

export const AuthContext = createContext<AuthInfo>(defaultAuthInfo);

export const useAuth = () => useContext(AuthContext);

export type AuthType = "github" | "gitlab" | "google";

export const authUrl = (auth: AuthType, {
    invite,
    redirect,
    signinorup = "either",
}: {
    invite?: string;
    redirect?: string;
    signinorup?: "signin" | "signup" | "either";
} = {}) => {
    const url = new URL(`oauth/${encodeURIComponent(auth)}`, apiUrl);
    url.searchParams.set("signinorup", signinorup);
    const hutk = cookies.get("hubspotutk");
    if (hutk) {
        url.searchParams.set("hutk", hutk);
    }
    if (redirect) {
        // redirect MUST NOT contain protocol or host as the API does
        // not allow absolute redirect URLs.
        url.searchParams.set("redirect", getPathAndHash(redirect));
    }
    if (invite) {
        url.searchParams.set("invite", invite);
    }
    return url.toString();
};
const decodeJwtToken = (token: string) => {
    const payload = token.split(".")[1];
    const decodedPayload = atob(payload);
    const parsedPayload = JSON.parse(decodedPayload);
    return parsedPayload;
};

const fetchJwtToken = () => localStorage.getItem(storageKey);

export const jwtInvalidOrExpired = () => {
    const tokenRaw = fetchJwtToken();
    if (!tokenRaw) return true;
    try {
        const token = decodeJwtToken(tokenRaw);
        const now = Date.now() / 1000;
        if (token.exp == null) return true;
        if (token.exp < now) return true;
    } catch (error) {
        logger.error("Invalid JWT token", { error });
        return true;
    }
    return false;
};

if (typeof window !== "undefined") (window as any).jwtInvalidOrExpired = jwtInvalidOrExpired;

export const redirectToSignIn = (message?: string) => {
    const redirect = getPathAndHash(hashRemove(window.location.href, ["access_token", "error"]));
    const url = new URL(window.location.origin);
    url.pathname = appUrls.signin;
    url.searchParams.set("redirect", redirect);
    url.hash = `error=${encodeURIComponent(message ?? "Automatically signed out. Please sign in again.")}`;
    window.location.replace(url.toString());
};

export const logoutAndRedirectToSignIn = (auth: AuthInfo = defaultAuthInfo) => {
    auth.logout();
    redirectToSignIn();
};
