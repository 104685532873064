import { User } from "@adaptable/client/dist/api-types/users";
import posthog, { PostHogConfig } from "posthog-js";

import { adaptableUidName } from "utils/userId";

export const client = posthog;

const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;
const posthogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST;

// Set your feature flags in development here
const devFlags: Record<string, string | boolean> = {
    "experiment-pricing": "control",
    "short-app-guides": "control",
};


export function posthogEnabled() {
    return Boolean(posthogKey);
}

export const pageview = () => {
    if (!posthogEnabled()) return;

    posthog.capture("$pageview");
};

export const event = (action: string, data: Record<string, any> = {}) => {
    if (!posthogEnabled()) return;
    posthog.capture(action, data);
};

export const login = (userId: string, method: string, user: User) => {
    posthog.identify(userId, {
        email: user.email,
        githubId: user.githubId,
    });
    posthog.capture("login", {
        method,
    });
};

const config: Partial<PostHogConfig> = {
    api_host: posthogHost || "https://app.posthog.com",
    // Enable debug mode in development
    loaded: () => {
        // if (process.env.NODE_ENV === "development") posthog.debug();

        const uid = window.localStorage.getItem(adaptableUidName);
        if (uid) posthog.identify(uid);
        pageview();
    },
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
};

// Config for when we aren't communicating with PostHog
if (!posthogKey) {
    config.bootstrap = {
        featureFlags: devFlags,
    };
    // Don't try to get flags from PostHog
    config.advanced_disable_decide = true;
}


if (typeof window !== "undefined") {
    posthog.init(posthogKey || "invalidkeyfordev", config);
}
