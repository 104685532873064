import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { DefaultSeo } from "next-seo";

import Intercom from "components/Intercom";
import Notifications from "components/Notifications";
import PostHogProvider from "components/PostHogProvider";
import { GA_TRACKING_ID, pageview } from "utils/gtag";
import { installAdaptableFeature } from "utils/feature-flags";
import { hubspotEnabled, trackPageView } from "utils/hubspot";
import * as posthog from "utils/posthog";
import { ogImages } from "utils/seo";
import createEmotionCache from "utils/createEmotionCache";
import { CacheProvider, EmotionCache } from "@emotion/react";

import "assets/dependencies/bootstrap/css/bootstrap.min.css";
import "assets/dependencies/fontawesome/css/all.min.css";
import "assets/dependencies/wow/css/animate.css";
import "assets/sass/app.scss";
import "assets/css/adaptable.css";
import "slick-carousel/slick/slick.css";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "utils/theme";
import { useCanonical } from "utils/url";

installAdaptableFeature();
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export default function MyApp({
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
}) {
    const router = useRouter();
    const canonical = useCanonical();

    React.useEffect(() => {
        if (!GA_TRACKING_ID && !hubspotEnabled() && !posthog.posthogEnabled()) return undefined;
        const handleRouteChange = () => {
            pageview();
            trackPageView();
            posthog.pageview();
        };
        router.events.on("routeChangeComplete", handleRouteChange);
        router.events.on("hashChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
            router.events.off("hashChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    // NOTE(mark): This useEffect code is directly from the MUI example for Next.
    // They don't explain why it's required, so leaving it as-is for now.
    // https://github.com/mui-org/material-ui/tree/master/examples/nextjs
    // I *believe* this is actually required for correct operation so that
    // the server-side CSS is used upon rehydration, but deleted on first
    // refresh because the client will generate its own conflicting CSS.
    React.useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector("#jss-server-side");
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    return (
        <>
            {
            // HubSpot tracking/chat
                hubspotEnabled()
                    ? (
                        <Script
                            id="hs-script-loader"
                            src="https://js-na1.hs-scripts.com/21202254.js"
                            strategy="lazyOnload"
                        />
                    )
                    : null
            }
            <Script
                id="statuspage"
                src="https://adaptable.statuspage.io/embed/script.js"
                strategy="lazyOnload"
            />
            <Head>
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                />
            </Head>
            <DefaultSeo
                titleTemplate="%s | Adaptable.io"
                canonical={canonical}
                openGraph={{
                    url: canonical,
                    images: ogImages,
                    site_name: "Adaptable.io",
                }}
                twitter={{
                    site: "@adaptableio",
                    cardType: "summary_large_image",
                }}
            />

            <CacheProvider value={emotionCache}>
                <ThemeProvider theme={theme}>
                    <PostHogProvider>
                        <Intercom />
                        <Notifications>
                            <Component {...pageProps} />
                        </Notifications>
                    </PostHogProvider>
                </ThemeProvider>
            </CacheProvider>

        </>
    );
}
