export const adaptableUidName = "adaptable-uid";

export function getUserId() {
    if (typeof window === "undefined") return undefined;
    return window.localStorage.getItem(adaptableUidName) || undefined;
}

export function setUserId(uid: string) {
    if (typeof window === "undefined") return;
    window.localStorage.setItem(adaptableUidName, uid);
}
