// IMPORTANT REMINDER: Population of process.env variables on the front-end
// happens via Webpack and is VERY stupid. You MUST write out accesses to
// environment variables textually like this: `process.env.SOME_VAR`. You
// CANNOT access process.env like a normal object.

export const apiUrl = process.env.NEXT_PUBLIC_API_URL || (process.env.NODE_ENV !== "production" ? "http://localhost:3030/" : undefined);
if (!apiUrl) throw new Error(`Environment variable NEXT_PUBLIC_API_URL must be defined`);

export const githubAppName = process.env.NEXT_PUBLIC_IO_GITHUB_APP_NAME || (process.env.NODE_ENV !== "production" ? "adaptable-dev" : undefined);
if (!githubAppName) throw new Error(`Environment variable NEXT_PUBLIC_IO_GITHUB_APP_NAME must be defined`);
export const githubAppInstallUrl = `https://github.com/apps/${githubAppName}/installations/new`;

export const hotjarId = process.env.NEXT_PUBLIC_HOTJAR_ID;

export const stripePublicKey = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY;
